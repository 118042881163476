import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import LoginScreen from "./app/features/screens/auth/loginscreen";
import DashboardLayout from "./layoutes/layout";
import DashboardScreen from "./app/features/screens/dashboard/dashboard";
import EmployeeScreen from "./app/features/screens/employee/employee";
import AttendanceScreen from "./app/features/screens/attendance/attendance";
import LeaveScreen from "./app/features/screens/leave/leave";
import ShiftScreen from "./app/features/screens/shift/shift";
import SettingsScreen from "./app/features/screens/settings/settings";
import EmployeeDetails from "./app/features/screens/employee/details/details";
import LeaveDetails from "./app/features/screens/leave/details";
import Designation from "./app/features/screens/designation/designation";
import Department from "./app/features/screens/department/department";
import LeaveType from "./app/features/screens/leaveType/leaveType";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./styles/app.scss";
import "./styles/form.scss";
import "./styles/login.scss";
import "./styles/dashboard.scss";
import RoleAndRightsScreen from "./app/features/screens/roleAndRights/roleAndRights";
import NocApproval from "./app/features/screens/nocApproval/nocApproval";
import ProtectedRoute from "./app/features/components/protectedRoute";
import axios from "axios";
import config from "../src/config";
import RegisterEmployeeScreen from "./app/features/screens/registerEmployee/registerEmployee";
import LevelsAndApprovalsScreen from "./app/features/screens/levels&Approval/levels&Approval";
import MapApprovalsScreen from "./app/features/screens/mapApprovals/mapApprovals";

const NotPermitted = () => (
  <div className="not-permitted">
    <h2>Not Permitted</h2>
    <p>You do not have permission to access this page.</p>
  </div>
);

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const [permissions, setPermissions] = useState([]);

  const navigate = useNavigate();
  // console.log(permissions);
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      setIsLoggedIn(true);
      getUserPermissions(authToken);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const handleLogin = async (token) => {
    setIsLoggedIn(true);
    localStorage.setItem("authToken", token);
    await getUserPermissions(token);
    setLoadingPermissions(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("authToken");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    localStorage.removeItem("userPermissions");
    setPermissions([]); // Clear permissions on logout
    toast.success("Logged out successfully.");
    navigate("/login");
  };

  const getUserPermissions = async (token) => {
    try {
      const response = await axios.get(
        `${config.baseUrl}api/RolesPermissions/GetMyPermissions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data?.status === 200) {
        localStorage.setItem(
          "userPermissions",
          JSON.stringify(response?.data?.data?.permissions)
        );
        setPermissions(response?.data?.data?.permissions);
        setLoadingPermissions(false);
      } else {
        toast.error("Failed to load permissions.");
      }
    } catch (error) {
      console.error("Permissions fetch error:", error);
      toast.error("Error fetching permissions.");
    }
  };

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path="/login"
          element={
            <LoginScreen handleLogin={handleLogin} isLoggedIn={isLoggedIn} />
          }
        />

        {isLoggedIn && !loadingPermissions && (
          <Route
            path="/"
            element={
              <DashboardLayout
                isLoggedIn={isLoggedIn}
                handleLogout={handleLogout}
              />
            }
          >
            {/* Dashboard Route */}
            <Route
              index
              element={
                <ProtectedRoute
                  element={DashboardScreen}
                  permission="Dashboard"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />

            {/*Register Employee Routes */}
            <Route
              path="registeremployee"
              element={
                <ProtectedRoute
                  element={RegisterEmployeeScreen}
                  permission="Register Employee"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />

            {/* Employee Routes */}
            <Route
              path="employee"
              element={
                <ProtectedRoute
                  element={EmployeeScreen}
                  permission="Employee"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />

            <Route
              path="employee/details/:id"
              element={
                <ProtectedRoute
                  element={EmployeeDetails}
                  permission="Employee"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />

            {/* Attendance Route */}
            <Route
              path="attendance"
              element={
                <ProtectedRoute
                  element={AttendanceScreen}
                  permission="Attendance"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />

            {/* Leave Routes */}
            <Route
              path="leave"
              element={
                <ProtectedRoute
                  element={LeaveScreen}
                  permission="Leave"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />
            <Route
              path="leave/details/:id"
              element={
                <ProtectedRoute
                  element={LeaveDetails}
                  permission="Leave"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />

            {/* Shift Route */}
            <Route
              path="shift"
              element={
                <ProtectedRoute
                  element={ShiftScreen}
                  permission="Shift"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />

            {/* Role and Rights Route */}
            <Route
              path="roleandrights"
              element={
                <ProtectedRoute
                  element={RoleAndRightsScreen}
                  permission="Roles & Rights"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />

            {/* NOC Approval Route */}
            <Route
              path="nocapproval"
              element={
                <ProtectedRoute
                  element={NocApproval}
                  permission="NOC Approval"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />

            {/*Approval Hirarchy Route */}
            <Route
              path="/levelsApprovals"
              element={
                <ProtectedRoute
                  element={LevelsAndApprovalsScreen}
                  permission="Levels Approvals"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />

            {/*Approval Map Route */}
            <Route
              path="/mapApprovals"
              element={
                <ProtectedRoute
                  element={MapApprovalsScreen}
                  permission="Map Approvals"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />

            {/* Settings Routes */}
            <Route
              path="settings/leaveType"
              element={
                <ProtectedRoute
                  element={LeaveType}
                  permission="Settings"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />
            <Route
              path="settings/department"
              element={
                <ProtectedRoute
                  element={Department}
                  permission="Settings"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />
            <Route
              path="settings/designation"
              element={
                <ProtectedRoute
                  element={Designation}
                  permission="Settings"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />
            <Route
              path="settings"
              element={
                <ProtectedRoute
                  element={SettingsScreen}
                  permission="Settings"
                  fallback={<NotPermitted />}
                  permissions={permissions}
                />
              }
            />
          </Route>
        )}
      </Routes>
    </>
  );
};

export default App;
