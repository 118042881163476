import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../components/action_buttons";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useFormik } from "formik";
import config from "../../../../config";
import { Button } from "primereact/button";
import CustomImagePreview from "../../components/custom_imagepreview";
import { Password } from "primereact/password";
import Spinner from "../../components/spinner";

const EditAddForm = ({
  onHide,
  editable,
  rowData,
  setShowDialog,
  GetEmployee,
}) => {
  const [currentPosts, setCurrentPosts] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [type, setType] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [maritialStatus, setmaritialStatus] = useState([]);
  const [districtsData, setdistrictsData] = useState([]);
  const [citiesData, setcitiesData] = useState([]);
  const [religionsData, setreligionsData] = useState([]);
  const [regionsData, setregionsData] = useState([]);
  const [genderData, setgenderData] = useState([]);
  const [roleData, setRoleData] = useState([]);

  const languageOptions = [
    { label: "English", value: "English" },
    { label: "Urdu", value: "Urdu" },
  ];

  const roleOptions = [
    { label: "DG", value: "DG" },
    { label: "HOD", value: "HOD" },
    { label: "Employee", value: "Employee" },
    { label: "User", value: "User" },
    { label: "Administrator", value: "Administrator" },
    { label: "SuperAdministrator", value: "SuperAdministrator" },
  ];

  // State for handling image and signature uploads
  const [fileName, setFileName] = useState(null);
  const [signatureFileName, setSignatureFileName] = useState(null);

  // Handler for employee image upload
  const handleEmployeeImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFileName(selectedFile); // Set the file name for the employee image
        formik.setFieldValue("employeePicture", reader.result); // Set Formik field for employee image
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // Clear the file and Formik field if no file is selected
      setFileName(null);
      formik.setFieldValue("employeePicture", "");
    }
  };

  // Handler for employee signature upload
  const handleEmployeeSignatureImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignatureFileName(selectedFile); // Set the file name for the employee signature
        formik.setFieldValue("employeeSignature", reader.result); // Set Formik field for employee signature
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // Clear the file and Formik field if no file is selected
      setSignatureFileName(null);
      formik.setFieldValue("employeeSignature", "");
    }
  };

  const removeEmployeeImage = () => {
    setFileName(null);
    formik.setFieldValue("employeePicture", "");
  };

  const removeEmployeeSignatureImage = () => {
    setSignatureFileName(null);
    formik.setFieldValue("employeeSignature", "");
  };

  const fetchFilters = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("authToken");

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers,
      };

      const [
        verticalResponse,
        departmentResponse,
        designationResponse,
        GetMaritialStatusResponse,
        GetDistrictsResponse,
        GetCitiesResponse,
        GetReligionsResponse,
        GetRegionsResponse,
        GetGenderResponse,
        GetRoleResponse,
      ] = await Promise.all([
        fetch(`${config.baseUrl}api/Posts/GetAll`, options),
        fetch(`${config.baseUrl}api/Department/GetAll`, options),
        fetch(`${config.baseUrl}api/Posts/GetAll`, options),
        fetch(`${config.baseUrl}api/Lov/GetMaritialStatus`, options),
        fetch(`${config.baseUrl}api/Lov/GetDistricts`, options),
        fetch(`${config.baseUrl}api/Lov/GetCities`, options),
        fetch(`${config.baseUrl}api/Lov/GetReligions`, options),
        fetch(`${config.baseUrl}api/Lov/GetRegions`, options),
        fetch(`${config.baseUrl}api/Lov/GetGender`, options),
        fetch(`${config.baseUrl}api/RolesPermissions/GetAllRoles`, options),
      ]);

      if (
        !verticalResponse.ok ||
        !departmentResponse.ok ||
        !designationResponse.ok ||
        !GetMaritialStatusResponse.ok ||
        !GetDistrictsResponse.ok ||
        !GetCitiesResponse.ok ||
        !GetReligionsResponse.ok ||
        !GetGenderResponse.ok || 
        !GetRoleResponse
      ) {
        throw new Error("Error fetching filter data");
      }

      // Parse each response to JSON
      const typeData = await verticalResponse.json();
      const departmentsData = await departmentResponse.json();
      const designationsData = await designationResponse.json();
      const maritialStatusData = await GetMaritialStatusResponse.json();
      const districtsData = await GetDistrictsResponse.json();
      const citiesData = await GetCitiesResponse.json();
      const religionsData = await GetReligionsResponse.json();
      const regionsData = await GetRegionsResponse.json();
      const genderData = await GetGenderResponse.json();
      const roleData = await GetRoleResponse.json();

      // Set data for each filter
      setType(typeData?.data);

      // Map and set designations and departments
      const departmentOptions = departmentsData.data.map((department) => ({
        label: department.name,
        value: department.id,
      }));
      setDepartments(departmentOptions);

      const desigOptions = designationsData.data.map((designation) => ({
        label: designation.name,
        value: designation.id,
      }));
      setDesignations(desigOptions);

      const roleOptions = roleData.data.map((role) => ({
        label: role.name,
        value: role.id,
      }));
      setRoleData(roleOptions);

      // Map and set data for the last five APIs

      // Marital Status
      const maritialStatusOptions = maritialStatusData.data.map((status) => ({
        label: status.name,
        value: status.id,
      }));
      setmaritialStatus(maritialStatusOptions);

      // Districts
      const districtOptions = districtsData.data.map((district) => ({
        label: district.name,
        value: district.id,
      }));
      setdistrictsData(districtOptions);

      // Cities
      const citiesOptions = citiesData.data.map((city) => ({
        label: city.name,
        value: city.id,
      }));
      setcitiesData(citiesOptions);

      // Religions
      const religionsOptions = religionsData.data.map((religion) => ({
        label: religion.name,
        value: religion.id,
      }));
      setreligionsData(religionsOptions);

      // Regions
      const regionsOptions = regionsData.data.map((region) => ({
        label: region.name,
        value: region.id,
      }));
      setregionsData(regionsOptions);

      // Genders
      const genderOptions = genderData.data.map((gender) => ({
        label: gender.name,
        value: gender.id,
      }));
      setgenderData(genderOptions);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching filters:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  // console.log("citiesData", citiesData)

  const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required("Employee name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    role: Yup.string().required("Role is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{11}$/, "Phone number must be exactly 11 digits"),
    password: editable
      ? Yup.string().optional()
      : Yup.string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters")
          .matches(
            /[a-z]/,
            "Password must contain at least one lowercase letter"
          )
          .matches(
            /[A-Z]/,
            "Password must contain at least one uppercase letter"
          )
          // .matches(/\d/, "Password must contain at least one number")
          .matches(
            /[!@#$%^&*(),.?":{}|<>]/,
            "Password must contain at least one special character"
          ),
          userName: Yup.string()
          .required("Username is required")
          .matches(
            /^[a-zA-Z0-9]+$/, // Allows only letters and digits
            "Username must contain only letters and digits"
          ),

    departmentId: Yup.number().required("Department is required"),

    departmentId: Yup.string()
      .nullable() // This allows null values
      .typeError("Please enter a valid string for Department") // Custom error for invalid types
      .required("Department post is required"),

    cnic: Yup.string()
      .matches(/^\d{13}$/, "CNIC must be exactly 13 digits")
      .required("CNIC is required"),
    relatedTo: Yup.string().required("Related to field is required"),
    dateOfBirth: Yup.date()
      .typeError("Please enter a valid date for Date of Birth")
      .required("Date of Birth is required"),
    passportNumber: Yup.string()
      .matches(
        /^[a-zA-Z0-9]{9}$/,
        "Passport number must be exactly 9 alphanumeric characters"
      )
      .required("Passport number is required"),

    // National tax number must be numeric and between 7 and 15 digits
    nationalTaxNumber: Yup.string()
      .matches(/^\d{7,15}$/, "NTN must be between 7 and 15 digits")
      .required("NTN is required"),
    gender: Yup.string().required("Gender is required"),
    religion: Yup.string().required("Religion is required"),
    regionId: Yup.string().required("Religion is required"),
    maritalStatus: Yup.string().required("Marital status is required"),
    languages: Yup.string().required("Languages are required"),
    appointmentWithStatus: Yup.string().required(
      "Appointment status is required"
    ),
    cadre: Yup.string().required("Cadre is required"),
    occupationalGroupService: Yup.string().required(
      "Occupational group/service is required"
    ),
    seniorityPosition: Yup.string().required("Seniority position is required"),
    dateOfJoiningCurrent: Yup.date()
      .typeError("Please enter a valid date for Date of Joining (Current)")
      .required("Date of joining (current) is required"),

    dateOfJoiningPrevious: Yup.date()
      .typeError("Please enter a valid date for Date of Joining (Previous)")
      .required("Date of joining (previous) is required"),

    dateOfSuperannuation: Yup.date()
      .typeError("Please enter a valid date for Date of Superannuation")
      .required("Date of superannuation is required"),
    domicile: Yup.string().required("Domicile is required"),
    homeDistrict: Yup.string().required("Home district is required"),
    currentPostId: Yup.string()
      .nullable() // This allows null values
      .typeError("Please enter a valid string for Current Post") // Custom error for invalid types
      .required("Current post is required"),
    currentPostCity: Yup.string().required("Current post city is required"),
    employeeBatch: Yup.string().required("Employee batch is required"),
    currentlyWorkingIn: Yup.string().required(
      "Currently working in is required"
    ),
    employeePicture: Yup.mixed().required("Employee picture is required"),
    employeeSignature: Yup.mixed().required("Employee signature is required"),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      employeeName: "",
      email: "",
      role: "",
      phoneNumber: "",
      password: "",
      userName: "",
      departmentId: null,
      cnic: "",
      relatedTo: "",
      dateOfBirth: null,
      passportNumber: "",
      nationalTaxNumber: "",
      gender: "",
      religion: "",
      regionId: "",
      maritalStatus: "",
      languages: "",
      appointmentWithStatus: "",
      cadre: "",
      occupationalGroupService: "",
      seniorityPosition: "",
      dateOfJoiningCurrent: null,
      dateOfJoiningPrevious: null,
      dateOfSuperannuation: null,
      domicile: "",
      homeDistrict: "",
      currentPostId: null,
      currentPostCity: "",
      employeeBatch: "",
      currentlyWorkingIn: "",
      employeePicture: null,
      employeeSignature: null,
    },

    validationSchema,
    onSubmit: async (data) => {
      const token = localStorage.getItem("authToken");

      try {
        let response;
        setLoading(true);

        if (editable) {
          response = await axios.put(
            `${config.baseUrl}api/Employee/UpdateEmployee`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          setShowDialog(false);
          GetEmployee();
          toast.success("Updated successfully");
        } else {
          response = await axios.post(
            `${config.baseUrl}api/Employee/AddEmployee`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          setShowDialog(false);
          GetEmployee();
          toast.success("Employee added successfully");
        }
        setLoading(false);
        onHide();
      } catch (error) {
        // Check if the error response exists and display the message
        const errorMessage =
          error.response?.data?.message || "Something went wrong";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });
  // console.log("rowData",rowData);

  useEffect(() => {
    if (editable) {
      formik.setFieldValue("employeeName", rowData?.employeeName || "");
      formik.setFieldValue("email", rowData?.email || "");
      formik.setFieldValue("role", rowData?.roleId || "");
      formik.setFieldValue("phoneNumber", rowData?.phoneNumber || "");
      formik.setFieldValue("password", rowData?.password || "");
      formik.setFieldValue("userName", rowData?.userName || "");
      formik.setFieldValue("departmentId", rowData?.departmentId || null);
      formik.setFieldValue("cnic", rowData?.cnic || "");
      formik.setFieldValue("relatedTo", rowData?.relatedTo || "");
      formik.setFieldValue(
        "dateOfBirth",
        rowData?.dateOfBirth ? new Date(rowData.dateOfBirth) : null
      );
      formik.setFieldValue("passportNumber", rowData?.passportNumber || "");
      formik.setFieldValue(
        "nationalTaxNumber",
        rowData?.nationalTaxNumber || ""
      );

      formik.setFieldValue("languages", rowData?.languages || "");

      formik.setFieldValue("gender", Number(rowData?.genderId) || "");
      formik.setFieldValue("religion", Number(rowData?.religionId) || "");
      formik.setFieldValue("regionId", Number(rowData?.regionId) || "");
      formik.setFieldValue(
        "maritalStatus",
        Number(rowData?.maritalStatusId) || ""
      );
      formik.setFieldValue(
        "currentPostCity",
        Number(rowData?.currentPostCityId) || ""
      );
      formik.setFieldValue("homeDistrict", Number(rowData?.homeDistrict) || "");

      formik.setFieldValue(
        "appointmentWithStatus",
        rowData?.appointmentWithStatus || ""
      );
      formik.setFieldValue("cadre", rowData?.cadre || "");
      formik.setFieldValue(
        "occupationalGroupService",
        rowData?.occupationalGroupService || ""
      );
      formik.setFieldValue(
        "seniorityPosition",
        rowData?.seniorityPosition || ""
      );
      formik.setFieldValue(
        "dateOfJoiningCurrent",
        rowData?.dateOfJoiningCurrent
          ? new Date(rowData.dateOfJoiningCurrent)
          : null
      );
      formik.setFieldValue(
        "dateOfJoiningPrevious",
        rowData?.dateOfJoiningPrevious
          ? new Date(rowData.dateOfJoiningPrevious)
          : null
      );
      formik.setFieldValue(
        "dateOfSuperannuation",
        rowData?.dateOfSuperannuation
          ? new Date(rowData.dateOfSuperannuation)
          : null
      );
      formik.setFieldValue("domicile", rowData?.domicile || "");
      // formik.setFieldValue("homeDistrict", rowData?.homeDistrict || "");
      formik.setFieldValue("currentPostId", rowData?.currentPostId || null);
      // formik.setFieldValue("currentPostCity", rowData?.currentPostCity || "");
      formik.setFieldValue("employeeBatch", rowData?.employeeBatch || "");
      formik.setFieldValue(
        "currentlyWorkingIn",
        Number(rowData?.currentlyWorkingIn) || ""
      );

      // formik.setFieldValue("employeePicture", rowData?.employeePicture || null);
      const employeePicturePath = rowData?.employeePicture
        ? `${config.baseUrl}${rowData.employeePicture}`
        : null;
      formik.setFieldValue("employeePicture", employeePicturePath);

      const employeeSignaturePath = rowData?.employeeSignature
        ? `${config.baseUrl}${rowData.employeeSignature}`
        : null;
      formik.setFieldValue("employeeSignature", employeeSignaturePath);

      // formik.setFieldValue(
      //   "employeeSignature",
      //   rowData?.employeeSignature || null
      // );

      setSignatureFileName(rowData?.employeeSignature);
      setFileName(rowData?.employeePicture);
      if (rowData?.id) {
        formik.setFieldValue("id", rowData.id); // Only set ID if it's editable
      }
    }
  }, [editable, rowData]);

  // console.log("rowData", rowData);
  // console.log("genderData", genderData);
  return (
    <div className="main-form">
      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field col-12 md:col-3">
            <label htmlFor="name">
              Employee Name<span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              maxLength={30}
              id="employeeName"
              value={formik.values.employeeName}
              onChange={formik.handleChange}
              placeholder="Enter Name"
            />
            {formik.errors.employeeName && formik.touched.employeeName && (
              <small className="p-error">{formik.errors.employeeName}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="userName">
              User Name<span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              maxLength={10}
              id="userName"
              value={formik.values.userName}
              onChange={formik.handleChange}
              placeholder="Enter Username"
            />
            {formik.errors.userName && formik.touched.userName && (
              <small className="p-error">{formik.errors.userName}</small>
            )}
          </div>
          {!editable && (
            <div className="field col-12 md:col-3 pass-pass">
              <label htmlFor="password">
                Password<span className="Staric-Custom text-danger"> *</span>
              </label>
              <Password
                toggleMask
                feedback={false}
                id="password"
                maxLength={8}
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                placeholder="Enter Password"
              />
              {formik.errors.password && formik.touched.password && (
                <small className="p-error">{formik.errors.password}</small>
              )}
            </div>
          )}
          <div className="field col-12 md:col-3">
            <label htmlFor="cnic">
              CNIC<span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              id="cnic"
              value={formik.values.cnic}
              onChange={formik.handleChange}
              placeholder="Enter CNIC"
              maxLength={13}
            />
            {formik.errors.cnic && formik.touched.cnic && (
              <small className="p-error">{formik.errors.cnic}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="email">
              Email<span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="Enter Email"
            />
            {formik.errors.email && formik.touched.email && (
              <small className="p-error">{formik.errors.email}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="phoneNumber">
              Phone Number<span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              id="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              maxLength={11}
              placeholder="Enter Phone Number"
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber && (
              <small className="p-error">{formik.errors.phoneNumber}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="currentPostId">
              Current Post<span className="Staric-Custom text-danger"> *</span>
            </label>
            <Dropdown
              filter
              id="currentPostId"
              name="currentPostId"
              value={formik.values.currentPostId}
              options={designations}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Select Current Post"
            />
            {formik.touched.currentPostId && formik.errors.currentPostId && (
              <small className="p-error">{formik.errors.currentPostId}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="departmentId">
              Department<span className="Staric-Custom text-danger"> *</span>
            </label>
            <Dropdown
              filter
              id="departmentId"
              name="departmentId"
              value={formik.values.departmentId}
              options={departments}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Select Department"
            />
            {formik.touched.departmentId && formik.errors.departmentId && (
              <small className="p-error">{formik.errors.departmentId}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="gender">
              Gender<span className="Staric-Custom text-danger"> *</span>
            </label>
            <Dropdown
              filter
              id="gender"
              name="gender"
              value={formik.values.gender}
              options={genderData}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Select Gender"
            />
            {formik.touched.gender && formik.errors.gender && (
              <small className="p-error">{formik.errors.gender}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="languages">
              Language<span className="Staric-Custom text-danger"> *</span>
            </label>
            <Dropdown
              filter
              id="languages"
              name="languages"
              value={formik.values.languages}
              options={languageOptions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Select Language"
            />
            {formik.touched.languages && formik.errors.languages && (
              <small className="p-error">{formik.errors.languages}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="cadre">
              Cadre / Ex Cadre
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              id="cadre"
              maxLength={30}
              value={formik.values.cadre}
              onChange={formik.handleChange}
              placeholder="Enter Cadre"
            />
            {formik.errors.cadre && formik.touched.cadre && (
              <small className="p-error">{formik.errors.cadre}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="occupationalGroupService">
              Occupational Group Service
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              maxLength={30}
              id="occupationalGroupService"
              value={formik.values.occupationalGroupService}
              onChange={formik.handleChange}
              placeholder="Enter Occupational Group Service"
            />
            {formik.errors.occupationalGroupService &&
              formik.touched.occupationalGroupService && (
                <small className="p-error">
                  {formik.errors.occupationalGroupService}
                </small>
              )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="seniorityPosition">
              Seniority Position
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              maxLength={30}
              id="seniorityPosition"
              value={formik.values.seniorityPosition}
              onChange={formik.handleChange}
              placeholder="Enter Seniority Position"
            />
            {formik.errors.seniorityPosition &&
              formik.touched.seniorityPosition && (
                <small className="p-error">
                  {formik.errors.seniorityPosition}
                </small>
              )}
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="dateOfJoiningCurrent">
              Date of Joining (Current)
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <Calendar
              id="dateOfJoiningCurrent"
              value={formik.values.dateOfJoiningCurrent}
              onChange={(e) =>
                formik.setFieldValue("dateOfJoiningCurrent", e.value)
              } // Capture selected date
              placeholder="Enter Date of Joining (Current)"
              showIcon
            />
            {formik.errors.dateOfJoiningCurrent &&
              formik.touched.dateOfJoiningCurrent && (
                <small className="p-error">
                  {formik.errors.dateOfJoiningCurrent}
                </small>
              )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="dateOfJoiningPrevious">
              Date of Joining (Previous)
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <Calendar
              id="dateOfJoiningPrevious"
              value={formik.values.dateOfJoiningPrevious}
              onChange={(e) =>
                formik.setFieldValue("dateOfJoiningPrevious", e.value)
              } // Capture selected date
              placeholder="Enter  Date of Joining (Previous)"
              showIcon
            />
            {formik.errors.dateOfJoiningPrevious &&
              formik.touched.dateOfJoiningPrevious && (
                <small className="p-error">
                  {formik.errors.dateOfJoiningPrevious}
                </small>
              )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="dateOfSuperannuation">
              Date of Superannuation
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <Calendar
              id="dateOfSuperannuation"
              value={formik.values.dateOfSuperannuation}
              onChange={(e) =>
                formik.setFieldValue("dateOfSuperannuation", e.value)
              } // Capture selected date
              placeholder="Enter  Date of Superannuation"
              showIcon
            />
            {formik.errors.dateOfSuperannuation &&
              formik.touched.dateOfSuperannuation && (
                <small className="p-error">
                  {formik.errors.dateOfSuperannuation}
                </small>
              )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="dateOfBirth">
              Date of Birth<span className="Staric-Custom text-danger"> *</span>
            </label>
            <Calendar
              id="dateOfBirth"
              value={formik.values.dateOfBirth}
              onChange={(e) => formik.setFieldValue("dateOfBirth", e.value)} // Capture selected date
              placeholder="Enter Date of Birth"
              showIcon
            />
            {formik.errors.dateOfBirth && formik.touched.dateOfBirth && (
              <small className="p-error">{formik.errors.dateOfBirth}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="appointmentWithStatus">
              Appointment With Status
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              maxLength={30}
              id="appointmentWithStatus"
              value={formik.values.appointmentWithStatus}
              onChange={formik.handleChange}
              placeholder="Enter Appointment With Status"
            />
            {formik.errors.appointmentWithStatus &&
              formik.touched.appointmentWithStatus && (
                <small className="p-error">
                  {formik.errors.appointmentWithStatus}
                </small>
              )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="domicile">
              Domicile Number
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              id="domicile"
              value={formik.values.domicile}
              onChange={formik.handleChange}
              placeholder="Enter Domicile Number"
            />
            {formik.errors.domicile && formik.touched.domicile && (
              <small className="p-error">{formik.errors.domicile}</small>
            )}
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="maritalStatus">
              Marital Status
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <Dropdown
              filter
              id="maritalStatus"
              name="maritalStatus"
              value={formik.values.maritalStatus}
              options={maritialStatus}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Select Marital Status"
            />
            {formik.touched.maritalStatus && formik.errors.maritalStatus && (
              <small className="p-error">{formik.errors.maritalStatus}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="religion">
              Religion<span className="Staric-Custom text-danger"> *</span>
            </label>
            <Dropdown
              id="religion"
              name="religion"
              value={formik.values.religion}
              options={religionsData}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Select Religion"
            />
            {formik.touched.religion && formik.errors.religion && (
              <small className="p-error">{formik.errors.religion}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="relatedTo">
              Related To<span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              id="relatedTo"
              value={formik.values.relatedTo}
              onChange={formik.handleChange}
              placeholder="Enter Related To"
            />
            {formik.errors.relatedTo && formik.touched.relatedTo && (
              <small className="p-error">{formik.errors.relatedTo}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="passportNumber">
              Passport Number
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              id="passportNumber"
              value={formik.values.passportNumber}
              onChange={formik.handleChange}
              placeholder="Enter Passport Number"
              maxLength={9}
            />
            {formik.errors.passportNumber && formik.touched.passportNumber && (
              <small className="p-error">{formik.errors.passportNumber}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="nationalTaxNumber">
              National Tax Number
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              minLength={7}
              maxLength={15}
              id="nationalTaxNumber"
              value={formik.values.nationalTaxNumber}
              onChange={formik.handleChange}
              placeholder="Enter National Tax Number"
            />
            {formik.errors.nationalTaxNumber &&
              formik.touched.nationalTaxNumber && (
                <small className="p-error">
                  {formik.errors.nationalTaxNumber}
                </small>
              )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="role">
              Role<span className="Staric-Custom text-danger"> *</span>
            </label>
            <Dropdown
              filter
              id="role"
              name="role"
              value={formik.values.role}
              options={roleData}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Select Role"
            />
            {formik.touched.role && formik.errors.role && (
              <small className="p-error">{formik.errors.role}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="regionId">
              Region<span className="Staric-Custom text-danger"> *</span>
            </label>
            <Dropdown
              filter
              id="regionId"
              name="regionId"
              value={formik.values.regionId}
              options={regionsData}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Select regionId"
            />
            {formik.touched.regionId && formik.errors.regionId && (
              <small className="p-error">{formik.errors.regionId}</small>
            )}
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="homeDistrict">
              Home District<span className="Staric-Custom text-danger"> *</span>
            </label>
            <Dropdown
              filter
              id="homeDistrict"
              name="homeDistrict"
              value={formik.values.homeDistrict}
              options={districtsData}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Select Home District"
            />
            {formik.touched.homeDistrict && formik.errors.homeDistrict && (
              <small className="p-error">{formik.errors.homeDistrict}</small>
            )}
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="currentPostCity">
              Current Post City
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <Dropdown
              filter
              id="currentPostCity"
              name="currentPostCity"
              value={formik.values.currentPostCity}
              options={citiesData}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Select currentPostCity"
            />
            {formik.touched.currentPostCity &&
              formik.errors.currentPostCity && (
                <small className="p-error">
                  {formik.errors.currentPostCity}
                </small>
              )}
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="employeeBatch">
              Employee Batch
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              maxLength={10}
              id="employeeBatch"
              value={formik.values.employeeBatch}
              onChange={formik.handleChange}
              placeholder="Enter Employee Batch"
            />
            {formik.errors.employeeBatch && formik.touched.employeeBatch && (
              <small className="p-error">{formik.errors.employeeBatch}</small>
            )}
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="currentlyWorkingIn">
              Currently Working In
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <Dropdown
              filter
              id="currentlyWorkingIn"
              name="currentlyWorkingIn"
              value={formik.values.currentlyWorkingIn}
              options={citiesData}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Select Currently Working In"
            />
            {formik.touched.currentlyWorkingIn &&
              formik.errors.currentlyWorkingIn && (
                <small className="p-error">
                  {formik.errors.currentlyWorkingIn}
                </small>
              )}
          </div>
        </div>

        <div className="p-fluid formgrid grid">
          {/* Employee Image Upload */}
          <div className="field col-12 md:col-3">
            <label>
              Attach Picture{" "}
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <div className="image-uploader-container">
              {!fileName && !formik.values.employeePicture && (
                <label className="image-upload-button">
                  <input
                    type="file"
                    onChange={handleEmployeeImageChange}
                    style={{ display: "none" }}
                    accept=".png, .jpg, .jpeg, .svg"
                  />
                  <div className="add-icon">+</div>
                </label>
              )}
              {(fileName || formik?.values?.employeePicture) && (
                <div className="image-preview-container">
                  <CustomImagePreview
                    src={
                      fileName instanceof File
                        ? URL.createObjectURL(fileName) // Use createObjectURL for File objects
                        : formik?.values.employeePicture // Direct URL from formik values for existing images
                    }
                    alt="Uploaded"
                    className="image-preview"
                  />

                  <button
                    className="remove-image-button"
                    onClick={removeEmployeeImage}
                  >
                    &times;
                  </button>
                </div>
              )}
            </div>
            {formik.errors.employeePicture &&
              formik.touched.employeePicture && (
                <small className="p-error">
                  {formik.errors.employeePicture}
                </small>
              )}
          </div>

          {/* Employee Signature Upload */}
          <div className="field col-12 md:col-3">
            <label htmlFor="employeeSignature">
              Employee Signature{" "}
              <span className="Staric-Custom text-danger"> *</span>
            </label>
            <div className="image-uploader-container">
              {!signatureFileName && !formik.values.employeeSignature && (
                <label className="image-upload-button">
                  <input
                    type="file"
                    onChange={handleEmployeeSignatureImageChange}
                    style={{ display: "none" }}
                    accept=".png, .jpg, .jpeg, .svg"
                  />
                  <div className="add-icon">+</div>
                </label>
              )}
              {(signatureFileName || formik.values.employeeSignature) && (
                <div className="image-preview-container">
                  <CustomImagePreview
                    src={
                      signatureFileName instanceof File
                        ? URL.createObjectURL(signatureFileName)
                        : formik.values.employeeSignature
                    }
                    alt="Uploaded"
                    className="image-preview"
                  />

                  <button
                    className="remove-image-button"
                    onClick={removeEmployeeSignatureImage}
                  >
                    &times;
                  </button>
                </div>
              )}
            </div>
            {formik.errors.employeeSignature &&
              formik.touched.employeeSignature && (
                <small className="p-error">
                  {formik.errors.employeeSignature}
                </small>
              )}
          </div>
        </div>

        <div className="p mt-4 form-buttons">
          <ActionButtons
            loading={loading}
            onCancel={onHide}
            onSave={formik.handleSubmit}
            saveLabel={editable ? "Update Changes" : "Save Changes"}
            showSave={true}
            cancelLabel="Cancel"
          />
        </div>
      </form>
    </div>
  );
};

export default EditAddForm;
