import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import editIcon from "../../../../assets/assests/edit.png";
import eyeIcon from "../../../../assets/assests/eye.png";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import EditAddForm from "./edit-add-form";
import config from "../../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../components/spinner";
import { Tag } from "primereact/tag";
import { TabView, TabPanel } from "primereact/tabview";
import usePermissions from "../../components/hooks/usePermissions";

export default function NocApproval() {
  const [globalFilter, setGlobalFilter] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [res, setRes] = useState();
  const [rowselect, setRowselect] = useState(null);
  const [editable, setEditable] = useState();
  const [loading, setLoading] = useState(false);
  const [nocList, setNocList] = useState([]);
  const [showDocDialog, setShowDocDialog] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [nocDialogVisible, setNocDialogVisible] = useState(false);
  const [selectedNocPath, setSelectedNocPath] = useState(null);
  const [nocLoading, setNocLoading] = useState(true);

  const permissions = usePermissions("NOC Approval");

  const GetNOCList = async () => {
    const token = localStorage.getItem("authToken");
    setLoading(true);

    try {
      const response = await fetch(
        `${config.baseUrl}api/NOC/GetNOCRequestsForApproval`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (data.status === 200) {
        setNocList(data.data || []);
      }
    } catch (error) {
      console.error("Error fetching NOC list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetNOCList();
  }, [res]);

  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setShowDialog(true);
  };

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const toggleDialogMode = (editMode) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="action-buttons flex">
        {permissions?.edit && (
          <Button
            className=" p-button-edit p-0 mr-3"
            onClick={() => {
              toggleDialogMode(true);
              editAction(rowData);
            }}
            aria-label="Edit"
          >
            <img src={editIcon} alt="Edit" />
          </Button>
        )}
      </div>
    );
  };

  const filteredNocList = {
    pending: nocList.filter((item) => item.status === "Pending"),
    approved: nocList.filter((item) => item.status === "Approved"),
    rejected: nocList.filter((item) => item.status === "Rejected"),
  };

  const renderStatusTag = (status) => {
    const statusClasses = {
      Approved: "custom-success-tag",
      Rejected: "custom-danger-tag",
      Pending: "custom-info-tag",
    };
    return (
      <Tag
        className={statusClasses[status] || "custom-info-tag"}
        value={status}
      />
    );
  };

  const renderDocumentsLink = (rowData) => (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        setSelectedDocs(rowData.filePath);
        setShowDocDialog(true);
      }}
      className="view-documents-link"
    >
      <img src={eyeIcon} alt="View Documents" /> View Documents
    </a>
  );

  const renderNocLink = (rowData) => {
    return (
      <>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setSelectedNocPath(rowData.nocPath);
            setNocDialogVisible(true);
            setNocLoading(true);
          }}
          className="view-documents-link"
        >
          <img src={eyeIcon} alt="View NOC" /> View NOC
        </a>
      </>
    );
  };

  const columnsData = [
    { field: "employeeName", header: "Employee Name" },
    { field: "department", header: "Department" },
    { field: "nocTypeName", header: "Type" },
    { field: "modifiedDate", header: "Date" },
    {
      field: "status",
      header: "STATUS",
      body: (rowData) => renderStatusTag(rowData.status),
    },
    {
      field: "remarks",
      header: "Remarks",
      body: (rowData) => rowData.remarks,
    },
    // {
    //   field: "documents",
    //   header: "Documents",
    //   body: renderDocumentsLink,
    // },
    // {
    //   field: "nocPath",
    //   header: "NOC",
    //   body: renderNocLink,
    // },
    ...(permissions?.view
      ? [
          {
            field: "documents",
            header: "Documents",
            body: renderDocumentsLink,
          },
        ]
      : []),
    ...(permissions?.view
      ? [
          {
            field: "nocPath",
            header: "NOC",
            body: renderNocLink,
          },
        ]
      : []),
  ];

  const renderDataTable = (
    nocList,
    showActionColumn,
    showRemarksColumn,
    showNocColumn
  ) => (
    <DataTable
      emptyMessage="No record found."
      value={nocList}
      paginator
      rows={10}
      paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      globalFilter={globalFilter}
      className="custom-data-table"
    >
      {columnsData.map((data, index) =>
        data.field === "remarks" && !showRemarksColumn ? null : data.field ===
            "nocPath" && !showNocColumn ? null : (
          <Column
            key={index}
            field={data.field}
            header={data.header}
            body={data.body}
          />
        )
      )}
      {showActionColumn && <Column header="Action" body={actionTemplate} />}
    </DataTable>
  );

  return (
    <>
      
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">Status</div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <EditAddForm
          rowData={rowselect}
          setShowDialog={setShowDialog}
          editable={editable}
          onHide={onHide}
          formData={isEdit ? selectedData : null}
          setRes={setRes}
        />
      </Dialog>

      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">Documents</div>
          </div>
        }
        visible={showDocDialog}
        onHide={() => setShowDocDialog(false)}
        className="dialog-size"
      >
        <div className="documents-container">
          {selectedDocs.length > 0 ? (
            selectedDocs.map((doc, index) => (
              <div key={index} className="document-row">
                <span className="document-name">Document {index + 1}</span>

                <a
                  href={`${config.baseUrl}${
                    doc.startsWith("/") ? doc.slice(1) : doc
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="preview-icon"
                >
                  <img src={eyeIcon} alt="Preview" />
                </a>

                <a
                  href={`${config.baseUrl}${
                    doc.startsWith("/") ? doc.slice(1) : doc
                  }`}
                  download={`Document_${index + 1}`}
                  target="_blank"
                  className="download-icon"
                >
                  <i className="pi pi-download"></i>
                </a>
              </div>
            ))
          ) : (
            <p>No documents available</p>
          )}
        </div>
      </Dialog>

      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">NOC Preview</div>
          </div>
        }
        visible={nocDialogVisible}
        onHide={() => setNocDialogVisible(false)}
        className="dialog-size"
      >
        {nocLoading && (
          <div className="spinner-overlay">
            <Spinner />
          </div>
        )}

        {selectedNocPath ? (
          <iframe
            src={`${config.baseUrl}${selectedNocPath.replace(/^\//, "")}`}
            title="NOC Preview"
            className="pdf-preview"
            style={{
              width: "100%",
              height: "400px",
              border: "none",
            }}
            onLoad={() => setNocLoading(false)}
          />
        ) : (
          <p>No NOC Document available</p>
        )}
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">NOC Approval</h5>
        </div>

        <div className="col-12 md:col-6 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>
      </div>
      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}

      <div className="card">
        <TabView className="custom-tabview">
          <TabPanel header="Pending">
            {renderDataTable(filteredNocList.pending, true, false, false)}{" "}
          </TabPanel>
          <TabPanel header="Approved">
            {renderDataTable(filteredNocList.approved, false, false, true)}{" "}
          </TabPanel>
          <TabPanel header="Rejected">
            {renderDataTable(filteredNocList.rejected, false, true, false)}{" "}
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}
