import React from 'react';
import "../../../styles/colors.scss";
import Papa from "papaparse";
import { Tooltip } from 'primereact/tooltip';


export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date
    .toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/,/, "");
};

export const formatTo12Hour = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";  // Return a placeholder for invalid date
  }

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,  // 12-hour format
  };
  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate;
};


export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatCapitalizeFirstLetter = (rowData, field) => {
  if (!rowData || rowData[field] === undefined || rowData[field] === null) {
    return "";
  }

  return capitalizeFirstLetter(rowData[field]);
};

export const statusColors = (rowData) => {
  let color;
  switch (rowData.status || rowData.periorty) {
    case "pending":
    case "Pending":
    case "medium":
      color = "var(--pending-color)";
      break;
    case "rejected":
      color = "#F40000";
      break;
    case "active":
    case "Resolved":
    case "high":
      color = "var(--active-color)";
      break;
    case "In-Process":
    case "low":
      color = "var(--inprocess-color)";
      break;
    default:
      color = "var(--black-color)";
      break;
  }
  return (
    <span style={{ color }}>
      {formatCapitalizeFirstLetter(rowData, "status")}
      {formatCapitalizeFirstLetter(rowData, "periorty")}
    </span>
  );
};

export const exportCSV = (data, filename) => {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const hasModulePermission = (permissions, module, action) => {
  return permissions?.[module]?.[action] || false;
};

export default hasModulePermission;

export const renderTextWithTooltip = (rowData, field, tooltipPosition = "bottom") => {

  const capitalizedDescription = formatCapitalizeFirstLetter(rowData, field);

  if (!capitalizedDescription) {
    return "--";
  }

  const shortDescription = capitalizedDescription
    .split(" ")
    .slice(0, 6)
    .join(" ");
  
  return (
    <div
      className="hoverable-description"
      data-pr-tooltip={capitalizedDescription}
      data-pr-position={tooltipPosition}
      data-pr-tooltip-options={{
        position: tooltipPosition,
        mouseTrack: true,
        mouseTrackTop: 15,
      }}
    >
      {shortDescription}
      {capitalizedDescription.split(" ").length > 2 && <span>...</span>}
      <Tooltip target=".hoverable-description" 
              style={{ maxWidth: "300px", whiteSpace: "nowrap", wordWrap: "break-word" }} 

      
      />
    </div>
  );
};