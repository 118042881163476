import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import ActionButtons from "../../components/action_buttons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import config from "../../../../config";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../components/spinner";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { InputTextarea } from "primereact/inputtextarea";

const EditAddForm = ({
  onHide,
  editable,
  viewable,
  setRes,
  rowData,
  setShowDialog,
  GetEmployeeAttendance,
}) => {
  const [loading, setLoading] = useState(false);
  const [moduleList, setModuleList] = useState([]);

  // Fetch modules from the backend
  const GetModules = async () => {
    const token = localStorage.getItem("authToken");
    setLoading(true);
    try {
      const response = await fetch(
        `${config.baseUrl}api/RolesPermissions/GetAllModules`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setModuleList(data.data); // Assuming the modules are in data.data array
      setLoading(false);
    } catch (error) {
      console.error("Error fetching modules:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetModules();
  }, []);

  // console.log("rowData", rowData);

  // console.log(rowData);

  // Define validation schema
  const validationSchema = Yup.object({
    roleName: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    dataVisibility: Yup.string().required("Required"),
    permissions: Yup.object().test(
      "permissions-check",
      "At least one permission (view, edit, add, delete) must be selected for at least one module",
      function (permissions) {
        if (!permissions) return true; // Allow empty permissions

        const hasPreFilledPermissions = Object.values(permissions).some(
          (perms) => perms.view || perms.edit || perms.add || perms.delete
        );

        if (hasPreFilledPermissions) return true; // Allow if pre-filled

        return moduleList.every((module) => {
          const perms = permissions[module.name] || {};
          return !perms.view && !perms.edit && !perms.add && !perms.delete;
        })
          ? this.createError()
          : true; // Validate all unchecked
      }
    ),
  });

  // Set up Formik for form state management
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      roleName: "",
      description: "",
      dataVisibility: "",
      permissions: {},
    },
    onSubmit: async (data) => {
      const token = localStorage.getItem("authToken");
      setLoading(true);

      // Prepare the payload
      const payload = {
        roleId: editable ? rowData?.id : undefined, // Include id for updates
        roleName: data.roleName,
        description: data.description,
        dataVisibility: data.dataVisibility,
        modules: moduleList.map((module) => ({
          module: module.name,
          moduleId: module.id,
          view: data.permissions[module.name]?.view || false,
          edit: data.permissions[module.name]?.edit || false,
          add: data.permissions[module.name]?.add || false,
          delete: data.permissions[module.name]?.delete || false,
        })),
      };

      // Add or Edit logic
      const url = editable
        ? `${config.baseUrl}api/RolesPermissions/UpdateRolesAndPermission`
        : `${config.baseUrl}api/RolesPermissions/AddRolesAndPermission`;

      try {
        const response = await axios.post(url, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        // console.log("response.status", response.status);
        if (response.status >= 200 && response.status < 300) {
          setRes(response);
          toast.success(
            `Role & Rights ${editable ? "updated" : "added"} successfully!`
          );
          GetEmployeeAttendance();
          setShowDialog(false);
        } else {
          throw new Error(response.data.message || "An error occurred");
        }
      } catch (error) {
        const errorMessage =
          error.response?.status === 409
            ? error.response.data.message
            : "Something went wrong";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleParentCheckboxChange = (e, module) => {
    const checked = e.target.checked;
    // Set all permission fields for the module based on parent checkbox state
    formik.setFieldValue(`permissions.${module}`, {
      view: checked,
      edit: checked,
      add: checked,
      delete: checked,
    });
  };

  const handleCheckboxChange = (e, module, field) => {
    formik.setFieldValue(`permissions.${module}.${field}`, e.target.checked);
  };

  // Permission template for DataTable
  const permissionTemplate = (rowData, field) => {
    const isChecked = !!formik.values.permissions?.[rowData.name]?.[field];
    const isViewChecked = !!formik.values.permissions?.[rowData.name]?.view;

    // Disable checkboxes for Edit, Add, and Delete if View is not checked
    const isDisabled = field !== "view" && !isViewChecked;

    return (
      <div className="custom-input" style={{ position: "relative" }}>
        <input
          type="checkbox"
          id={`${rowData.name}_${field}`}
          checked={isChecked}
          onChange={(e) => handleCheckboxChange(e, rowData.name, field)}
          disabled={isDisabled} // Disable based on View permission
        />
        {isDisabled && (
          <Tooltip
            target={`#${rowData.name}_${field}`}
            content="To proceed, kindly enable the 'View' permission first"
            showOnDisabled={true}
            position="top"
            className="custom-tooltip"
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (editable || viewable) {
      formik.setFieldValue("roleName", rowData?.name);
      formik.setFieldValue("description", rowData?.description);
      formik.setFieldValue("dataVisibility", rowData?.dataVisibilityMode);

      const mappedPermissions = {};
      rowData?.permissions.forEach((permission) => {
        mappedPermissions[permission?.moduleName] = {
          view: permission.view || false,
          edit: permission.edit || false,
          add: permission.add || false,
          delete: permission.delete || false,
        };
      });

      // Update formik permissions field
      formik.setFieldValue("permissions", mappedPermissions);
    }
  }, [editable, rowData, viewable]);

  return (
    <>
      <ToastContainer />
      <div className="main-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid formgrid grid">
            {loading && (
              <div className="spinner-overlay">
                <Spinner />
              </div>
            )}

            <div className="field col-12 md:col-4">
              <label htmlFor="roleName">
                Role Name <span className="text-danger">*</span>
              </label>
              <InputText
                id="roleName"
                placeholder="Enter Name"
                value={formik.values.roleName}
                onChange={formik.handleChange}
                name="roleName"
                disabled={viewable}
              />
              {formik.touched.roleName && formik.errors.roleName ? (
                <div className="error">{formik.errors.roleName}</div>
              ) : null}
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="dataVisibility">
                Data Visibility <span className="text-danger">*</span>
              </label>
              <Dropdown
                id="dataVisibility"
                name="dataVisibility"
                value={formik.values.dataVisibility}
                options={[
                  { label: "Self", value: 1 },
                  { label: "Regional", value: 2 },
                  { label: "All", value: 3 },
                ]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Select Data Visibility"
                disabled={viewable}
              />
              {formik.touched.dataVisibility &&
                formik.errors.dataVisibility && (
                  <small className="p-error">
                    {formik.errors.dataVisibility}
                  </small>
                )}
            </div>
            <div className="field col-12 md:col-12">
            <label htmlFor="description">
              Description <span className="text-danger">*</span>
            </label>
            {/* <InputText
                id="description"
                placeholder="Enter Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                name="description"
                disabled={viewable}
              /> */}
            <InputTextarea
              id="description"
              rows={3}
              name="description"
              placeholder="Enter Description"
              disabled={viewable}
              value={formik.values.description}
              onChange={formik.handleChange}
              className={
                formik.errors.description && formik.touched.description
                  ? "p-invalid"
                  : null
              }
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="error">{formik.errors.description}</div>
            ) : null}
          </div>
          </div>
         

          {/* DataTable for permissions */}
          <DataTable value={moduleList} className="mt-4">
            <Column field="name" header="Module" />

            <Column
              header="All"
              body={(rowData) => (
                <div className="custom-input" style={{ position: "relative" }}>
                  <input
                    type="checkbox"
                    id={`${rowData.name}_all`}
                    checked={
                      !!formik.values.permissions[rowData.name]?.view &&
                      !!formik.values.permissions[rowData.name]?.edit &&
                      !!formik.values.permissions[rowData.name]?.add &&
                      !!formik.values.permissions[rowData.name]?.delete
                    }
                    onChange={(e) =>
                      handleParentCheckboxChange(e, rowData.name)
                    }
                    disabled={!formik.values.permissions[rowData.name]?.view} // Disable if View is not checked
                  />
                  <label htmlFor={`${rowData.name}_all`}></label>
                  {!formik.values.permissions[rowData.name]?.view && (
                    <Tooltip
                      target={`#${rowData.name}_all`}
                      content="To enable permissions, kindly enable the 'View' permission first"
                      showOnDisabled={true}
                      position="top"
                      className="custom-tooltip"
                    />
                  )}
                </div>
              )}
            />

            <Column
              header="View"
              body={(rowData) => permissionTemplate(rowData, "view")}
            />
            <Column
              header="Edit"
              body={(rowData) => permissionTemplate(rowData, "edit")}
            />
            <Column
              header="Add"
              body={(rowData) => permissionTemplate(rowData, "add")}
            />
            <Column
              header="Delete"
              body={(rowData) => permissionTemplate(rowData, "delete")}
            />
          </DataTable>

          {formik.errors.permissions && (
            <div className="error">{formik.errors.permissions}</div>
          )}

          {!viewable && (
            <div className="p mt-4 form-buttons">
              <ActionButtons
                loading={loading}
                onCancel={onHide}
                onSave={formik.handleSubmit}
                saveLabel={editable ? "Update Changes" : "Save Changes"}
                showSave={true}
                cancelLabel="Cancel"
              />
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default EditAddForm;
