import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import ActionButtons from "../../components/action_buttons";
import axios from "axios";
import * as Yup from "yup";
import { FieldArray, useFormik, FormikProvider } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import config from "../../../../config";
import Spinner from "../../components/spinner";
import { InputTextarea } from "primereact/inputtextarea";

const EditAddForm = ({
  onHide,
  rowData,
  editable,
  viewable,
  setRes,
  GetAllLevelAndApprovals,
  setShowDialog,
}) => {
  const [roleData, setRoleData] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("authToken");
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        const options = {
          method: "GET",
          headers,
        };

        // Fetch roles
        const GetRoleResponse = await fetch(
          `${config.baseUrl}api/RolesPermissions/GetAllRoles`,
          options
        );
        if (!GetRoleResponse.ok) {
          throw new Error("Error fetching roles data");
        }
        const roleData = await GetRoleResponse.json();
        const roleOptions = roleData.data.map((role) => ({
          label: role.name,
          value: role.id,
        }));
        setRoleData(roleOptions);

        // Fetch actions
        const GetActionsResponse = await fetch(
          `${config.baseUrl}api/Lov/GetActions`,
          options
        );
        if (!GetActionsResponse.ok) {
          throw new Error("Error fetching actions data");
        }
        const actionsData = await GetActionsResponse.json();
        const actionOptions = actionsData.data.map((action) => ({
          label: action.name,
          value: action.id,
        }));
        setActionData(actionOptions);

        // Fetch employees
        const GetEmployeeResponse = await fetch(
          `${config.baseUrl}api/Employee/GetEmployeesNameAndDesignation`,
          options
        );
        if (!GetEmployeeResponse.ok) {
          throw new Error("Error fetching employee data");
        }
        const employeeData = await GetEmployeeResponse.json();
        const employeeOptions = employeeData.data.map((employee) => ({
          label: employee.name,
          value: employee.id,
        }));
        setEmployeeList(employeeOptions);
      } catch (error) {
        console.error("Error fetching filters:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFilters();
  }, []);

  const initialValues = {
    name: "",
    description: "",
    approvals: [
      {
        approvalTypeId: "",
        approverId: "",
        approvalOrder: 1,
        actionId: "", // New field for action
        isMandatory: "", // New field for mandatory
      },
    ],
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Action name is required"),
    description: Yup.string().required("Description is required"),
    approvals: Yup.array().of(
      Yup.object().shape({
        approvalTypeId: Yup.string().required("Approved by is required"),
        approverId: Yup.string()
          .nullable()
          .required("Approver name is required"),
        actionId: Yup.string().required("Field for remarks is required"),
        isMandatory: Yup.boolean().required(
          "Mandatory field for remarks is required"
        ),
      })
    ),
  });

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (data) => {
      const token = localStorage.getItem("authToken");
      setLoading(true);

      const payload = {
        name: data.name,
        description: data.description,
        approvals: data.approvals
          ? data.approvals.map((approval, index) => ({
              ...approval,
              approvalOrder: index + 1,
            }))
          : [],
        ...(editable ? { id: rowData.id } : {}),
      };

      const url = `${config.baseUrl}api/LevelsAndApprovals/${
        editable ? "UpdateLevelAndApprovals" : "AddLevelAndApprovals"
      }`;

      const method = editable ? "PATCH" : "POST";

      try {
        const response = await axios({
          method,
          url,
          data: payload,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status >= 200 && response.status < 300) {
          setRes(response);
          toast.success(
            `Levels & Approvals ${editable ? "updated" : "added"} successfully!`
          );
          GetAllLevelAndApprovals();
          setShowDialog(false);
        } else {
          throw new Error(response.data.message || "An error occurred");
        }
      } catch (error) {
        console.error("API Error:", error.response || error.message);
        toast.error(error.response?.data?.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    },
  });

  const getApproverOptions = (approvalTypeId) => {
    if (approvalTypeId === 1) {
      return roleData.length > 0
        ? roleData
        : [{ label: "No options available", value: null }];
    } else if (approvalTypeId === 2) {
      return employeeList.length > 0
        ? employeeList
        : [{ label: "No options available", value: null }];
    }
    return [{ label: "No options available", value: null }];
  };

  useEffect(() => {
    if (editable && rowData) {
      formik.setFieldValue("name", rowData?.name || "");
      formik.setFieldValue("description", rowData?.description || "");
      if (rowData.approvals && rowData.approvals.length > 0) {
        const prefilledApprovals = rowData.approvals.map((approval) => ({
          approvalTypeId: approval.approvalTypeId,
          approverId: approval.approverId,
          approvalOrder: approval.approvalOrder,
          actionId: approval.actionId,
          isMandatory: approval.isMandatory,
        }));

        formik.setFieldValue("approvals", prefilledApprovals);
      } else {
        formik.setFieldValue("approvals", []);
      }
    }
  }, [editable, rowData]);

  return (
    <>
      <ToastContainer />
      <div className="main-form">
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            {loading && (
              <div className="spinner-overlay">
                <Spinner />
              </div>
            )}

            <div className="p-fluid formgrid grid">
              <div className="field col-12 md:col-4">
                <label htmlFor="name">
                  Level <span className="text-danger">*</span>
                </label>
                <InputText
                  id="name"
                  placeholder="Enter Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  name="name"
                  disabled={viewable}
                />
                {formik.touched.name && formik.errors.name ? (
                  <small className="p-error">{formik.errors.name}</small>
                ) : null}
              </div>

              <div className="field col-12 md:col-12">
                <label htmlFor="description">
                  Description <span className="text-danger">*</span>
                </label>
                <InputTextarea
                  id="description"
                  placeholder="Enter Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  name="description"
                  disabled={viewable}
                  rows={3}
                />
                {formik.touched.description && formik.errors.description ? (
                  <small className="p-error">{formik.errors.description}</small>
                ) : null}
              </div>
            </div>

            <FieldArray name="approvals">
              {({ remove, push }) => (
                <>
                  <div>
                    <label>
                      Approval Actioners
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  {formik.values.approvals.map((action, index) => (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderBottom: "1px solid #e7e1e1",
                          paddingBottom: "20px",
                          margin: "10px 0px 20px",
                        }}
                      >
                        <div>
                          <label>{`Approval Hierarchy ${index + 1}`}</label>
                        </div>

                        <div>
                          {index === 0 && !viewable && (
                            <Button
                              type="button"
                              className=" custom-button-round p-button-success custom-add-remove"
                              onClick={() => {
                                push({
                                  approvalTypeId: "",
                                  approverId: "",
                                  approvalOrder:
                                    formik.values.approvals.length + 1,
                                  actionId: "",
                                  isMandatory: "",
                                });
                              }}
                              icon="pi pi-plus"
                            />
                          )}

                          {index > 0 && !viewable && (
                            <Button
                              type="button"
                              className="p-button-outlined custom-button-round p-button-danger custom-add-remove"
                              onClick={() => {
                                remove(index);
                              }}
                              icon="pi pi-minus"
                            />
                          )}
                        </div>
                      </div>

                      <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-3">
                          <label htmlFor={`approvals.${index}.approvalTypeId`}>
                            Approval By <span className="text-danger">*</span>
                          </label>
                          <Dropdown
                            filter
                            id={`approvals.${index}.approvalTypeId`}
                            name={`approvals.${index}.approvalTypeId`}
                            value={
                              formik.values.approvals[index].approvalTypeId
                            }
                            options={[
                              { label: "Role", value: 1 },
                              { label: "User", value: 2 },
                            ]}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `approvals.${index}.approvalTypeId`,
                                e.value
                              )
                            }
                            placeholder="Select Approval By"
                            disabled={viewable}
                          />

                          {formik.touched.approvals?.[index] &&
                            formik.errors.approvals?.[index]
                              ?.approvalTypeId && (
                              <small className="p-error">
                                {formik.errors.approvals[index].approvalTypeId}
                              </small>
                            )}
                        </div>

                        <div className="field col-12 md:col-3">
                          <label htmlFor={`approvals.${index}.approverId`}>
                            Approver Name <span className="text-danger">*</span>
                          </label>
                          <Dropdown
                            filter
                            id={`approvals.${index}.approverId`}
                            name={`approvals.${index}.approverId`}
                            value={formik.values.approvals[index].approverId}
                            options={getApproverOptions(
                              formik.values.approvals[index].approvalTypeId
                            )}
                            // onChange={(e) =>
                            //   formik.setFieldValue(
                            //     `approvals.${index}.approverId`,
                            //     e.value
                            //   )
                            // }
                            onChange={(e) => {
                              const selectedValue = e.value;
                              // Only set the value if it's a valid string
                              if (
                                selectedValue &&
                                typeof selectedValue === "string"
                              ) {
                                formik.setFieldValue(
                                  `approvals.${index}.approverId`,
                                  selectedValue
                                );
                              } else {
                                formik.setFieldValue(
                                  `approvals.${index}.approverId`,
                                  null
                                );
                              }
                            }}
                            placeholder="Select Approver Name"
                            disabled={viewable}
                          />

                          {formik.touched.approvals?.[index] &&
                            formik.errors.approvals?.[index]?.approverId && (
                              <small className="p-error">
                                {formik.errors.approvals[index].approverId}
                              </small>
                            )}
                        </div>

                        <div className="field col-12 md:col-3">
                          <label htmlFor={`approvals.${index}.actionId`}>
                            Field for remarks{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Dropdown
                            filter
                            id={`approvals.${index}.actionId`}
                            name={`approvals.${index}.actionId`}
                            value={formik.values.approvals[index].actionId}
                            options={actionData}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `approvals.${index}.actionId`,
                                e.value
                              )
                            }
                            placeholder="Select Field"
                            disabled={viewable}
                          />

                          {formik.touched.approvals?.[index] &&
                            formik.errors.approvals?.[index]?.actionId && (
                              <small className="p-error">
                                {formik.errors.approvals[index].actionId}
                              </small>
                            )}
                        </div>
                        <div className="field col-12 md:col-3">
                          <label htmlFor={`approvals.${index}.isMandatory`}>
                            Mandatory
                          </label>
                          <Dropdown
                            filter
                            id={`approvals.${index}.isMandatory`}
                            name={`approvals.${index}.isMandatory`}
                            value={formik.values.approvals[index].isMandatory}
                            options={[
                              {
                                label: "Mandatory Fields for remarks",
                                value: true,
                              },
                              {
                                label: "Non-Mandatory Fields for remarks",
                                value: false,
                              },
                            ]}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `approvals.${index}.isMandatory`,
                                e.value
                              )
                            }
                            placeholder="Select Mandatory"
                            disabled={viewable}
                          />
                          {formik.touched.approvals?.[index] &&
                            formik.errors.approvals?.[index]?.isMandatory && (
                              <small className="p-error">
                                {formik.errors.approvals[index].isMandatory}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </FieldArray>

            {!viewable && (
              <div className="form-buttons mt-4">
                <ActionButtons
                  loading={loading}
                  onCancel={onHide}
                  onSave={formik.handleSubmit}
                  saveLabel={editable ? "Update Changes" : "Save Changes"}
                  showSave={true}
                  cancelLabel="Cancel"
                />
              </div>
            )}
          </form>
        </FormikProvider>
      </div>
    </>
  );
};

export default EditAddForm;
