import { useEffect, useState } from "react";
import config from "../../../../config";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import ActionButtons from "../../components/action_buttons";
import { InputTextarea } from "primereact/inputtextarea";

const EditAddForm = ({ onHide, editable, setRes, rowData, setShowDialog }) => {
  const [apiRes, setApiRes] = useState();
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [pdfPreview, setPdfPreview] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);

  const approvalAction = rowData?.approvalAction;
  const mandatory = rowData?.isMandatory;

  const validationSchema = Yup.object({
    status: Yup.string().required("Status is required"),

    // Comments validation based on status and approvalAction
    // comments: Yup.string().when(["status"], {
    //   is: (status) => {
    //     console.log("Status:", status); // Log the status
    //     console.log("Approval Action:", approvalAction); // Log the approvalAction
    //     return (
    //       (status === "2" || status === "3") &&
    //       (approvalAction === "text" || approvalAction === "textarea")
    //     );
    //   },
    //   then: Yup.string().required("Comments are required for this status"),
    //   otherwise: Yup.string(),
    // }),

    // comments: Yup.string().when("status", {
    //   is: (status) => {
    //     console.log("Status:", status); // Log the status
    //     console.log("Approval Action:", approvalAction); // Log the approvalAction
    //     console.log("Mandatory:", mandatory); // Log the mandatory value
    //     return (
    //       (status === "2" || status === "3") &&
    //       (approvalAction === "text" || approvalAction === "textarea") &&
    //       mandatory
    //     );
    //   },
    //   then: Yup.string().required("Comments are required for this status"),
    //   otherwise: Yup.string(),
    // }),

    comments: Yup.string().when("status", {
      is: (status) => {
        // console.log("Status:", status); // Log the status
        // console.log("Approval Action:", approvalAction); // Log the approvalAction
        // console.log("Mandatory:", mandatory); // Log the mandatory value
        // Apply validation if status is "2" or "3"
        return (
          (status === "2" &&
            (approvalAction === "text" || approvalAction === "textarea") &&
            mandatory) ||
          // (status === "3" && (approvalAction === "text" || approvalAction === "textarea" || approvalAction === "file") && mandatory)
          status === "3"
        );
      },
      then: Yup.string().required("Comments are required for this status"),
      otherwise: Yup.string(),
    }),

    // NOC file validation based on status and approvalAction
    // nocFile: Yup.mixed().when(["status"], {
    //   is: (status) => {
    //     console.log("Status:", status); // Log the status
    //     console.log("Approval Action:", approvalAction);
    //     return status === "2" && approvalAction === "file";
    //   },
    //   then: Yup.mixed().required("NOC file is required"),
    //   otherwise: Yup.mixed(),
    // }),

    nocFile: Yup.mixed().when("status", {
      is: (status) => {
        // console.log("Status:", status); // Log the status
        // console.log("Approval Action:", approvalAction);
        // console.log("Mandatory:", mandatory);
        return status === "2" && approvalAction === "file" && mandatory;
      },
      then: Yup.mixed().required("NOC file is required"),
      otherwise: Yup.mixed(),
    }),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      status: "",
      comments: "",
      nocFile: "",
      ...(editable ? { id: rowData.id } : {}),
    },
    onSubmit: async (data) => {
      const token = localStorage.getItem("authToken");
      setLoading(true);

      const payload = {
        nocRequestId: rowData.id,
        status: data.status,
        remarks: data.comments,
        nocFile: data.nocFile,
      };

      try {
        const response = await axios.post(
          `${config.baseUrl}api/NOC/ApproveRejectNOC`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setApiRes(response);
        toast.success("StatusUpdated Successfully");
        setRes(response);
        setShowDialog(false);
      } catch (error) {
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    },
  });

  // console.log(rowData?.approvalAction, "fileee");

  const handleEmployeeImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Ensure the selected file is a PDF
      if (selectedFile.type === "application/pdf") {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFileName(selectedFile);
          formik.setFieldValue("nocFile", reader.result);
          setIsUploaded(true); // Update upload status
        };
        reader.readAsDataURL(selectedFile);
      } else {
        alert("Please upload a PDF file.");
        setFileName(null);
        formik.setFieldValue("nocFile", "");
        setIsUploaded(false); // Reset upload status
      }
    } else {
      setFileName(null);
      formik.setFieldValue("nocFile", "");
      setIsUploaded(false); // Reset upload status
    }
  };

  const statusOptions = [
    { label: "Approve", value: "2" },
    { label: "Reject", value: "3" },
  ];

  useEffect(() => {
    // console.log("Status changed to:", formik.values.status);
    formik.setFieldValue("comments", "");
    // console.log("Comments reset to:", formik.values.comments);
  }, [formik.values.status]);

  return (
    <>
      <div className="main-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-12">
              <label htmlFor="status">
                Status <span className="Staric-Custom text-danger"> *</span>
              </label>
              <Dropdown
                id="status"
                name="status"
                value={formik.values.status}
                options={statusOptions}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Select Status"
              />
              {formik.touched.status && formik.errors.status && (
                <small className="p-error">{formik.errors.status}</small>
              )}
            </div>

            {formik.values.status === "3" && (
              <div className="field col-12 md:col-12">
                <label htmlFor="comments">
                  Comments <span className="Staric-Custom text-danger"> *</span>
                </label>
                <InputText
                  id="comments"
                  placeholder="Enter Comments"
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  name="comments"
                />
                {formik.touched.comments && formik.errors.comments ? (
                  <div className="error">{formik.errors.comments}</div>
                ) : null}
              </div>
            )}

            {formik.values.status === "2" && (
              <>
                {rowData?.approvalAction === "file" && (
                  <div className="field col-12 md:col-12">
                    <label>
                      Upload NOC{" "}
                      <span className="Staric-Custom text-danger"> *</span>
                    </label>

                    <div className="image-uploader-container">
                      <label className="image-upload-button">
                        <input
                          type="file"
                          onChange={handleEmployeeImageChange}
                          style={{ display: "none" }}
                          accept=".pdf"
                        />
                        <div className="add-icon">+</div>
                      </label>
                    </div>

                    {/* Preview section */}
                    {fileName && (
                      <div
                        className="image-preview-container"
                        style={{ marginTop: "20px" }}
                      >
                        <iframe
                          src={URL.createObjectURL(fileName)}
                          title="PDF Preview"
                          className="pdf-preview"
                          style={{
                            width: "100%",
                            height: "300px",
                            border: "none",
                          }}
                        />
                      </div>
                    )}
                    {formik.errors.nocFile && formik.touched.nocFile && (
                      <small className="p-error">{formik.errors.nocFile}</small>
                    )}
                  </div>
                )}
                {(rowData?.approvalAction === "text" ||
                  rowData?.approvalAction === "textarea") && (
                  <div className="field col-12 md:col-12">
                    <label htmlFor="comments">
                      Comments{" "}
                      <span className="Staric-Custom text-danger"> *</span>
                    </label>
                    {rowData?.approvalAction === "text" ? (
                      <InputText
                        id="comments"
                        placeholder="Enter Comments"
                        value={formik.values.comments}
                        onChange={formik.handleChange}
                        name="comments"
                      />
                    ) : (
                      <InputTextarea
                        id="comments"
                        placeholder="Enter Comments"
                        value={formik.values.comments}
                        onChange={formik.handleChange}
                        name="comments"
                      />
                    )}
                    {formik.touched.comments && formik.errors.comments ? (
                      <div className="error">{formik.errors.comments}</div>
                    ) : null}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="p mt-4 form-buttons">
            <ActionButtons
              loading={loading}
              onCancel={onHide}
              onSave={formik.handleSubmit}
              saveLabel={editable ? "Update Changes" : "Save Changes"}
              showSave={true}
              cancelLabel="Cancel"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAddForm;
