import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import ActionButtons from "../../components/action_buttons";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../../config";
import Spinner from "../../components/spinner";
import { MultiSelect } from "primereact/multiselect";

const EditAddForm = ({
  onHide,
  rowData,
  editable,
  viewable,
  setRes,
  GetListApprovalsAndApprovalTypes,
  setShowDialog,
}) => {
  const [loading, setLoading] = useState(false);
  const [approvalsAndLevelsData, setApprovalsAndLevelsData] = useState([]);
  const [approvalTypesData, setApprovalTypesData] = useState([]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("authToken");
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

        // Fetch approvals and levels
        const GetApprovalsAndLevelsResponse = await fetch(
          `${config.baseUrl}api/Lov/GetAllApprovalsAndLevels`,
          { method: "GET", headers }
        );
        if (!GetApprovalsAndLevelsResponse.ok) {
          throw new Error("Error fetching approvals and levels data");
        }
        const approvalsAndLevelsData =
          await GetApprovalsAndLevelsResponse.json();
        const approvalsAndLevelsOptions = approvalsAndLevelsData.data.map(
          (item) => ({
            label: item.name,
            value: item.id,
          })
        );
        setApprovalsAndLevelsData(approvalsAndLevelsOptions);

        // Fetch approval types
        const GetApprovalTypesResponse = await fetch(
          `${config.baseUrl}api/Lov/GetApprovalTypes`,
          { method: "GET", headers }
        );
        if (!GetApprovalTypesResponse.ok) {
          throw new Error("Error fetching approval types data");
        }
        const approvalTypesData = await GetApprovalTypesResponse.json();
        const approvalTypesOptions = approvalTypesData.data.map((type) => ({
          label: type.name,
          value: type.id,
        }));
        setApprovalTypesData(approvalTypesOptions);
      } catch (error) {
        console.error("Error fetching filters:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFilters();
  }, []);

  const initialValues = {
    approvalRequestTypeId: editable ? rowData.approvalRequestTypeId : "",
    ApprovalHierarchyIds: editable ? rowData.ApprovalHierarchyIds : [],
  };

  const validationSchema = Yup.object({
    approvalRequestTypeId: Yup.number().required(
      "Approval Request is required"
    ),
    ApprovalHierarchyIds: Yup.array()
      .min(1, "Select at least one approval level")
      .required("Approval Levels are required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (data) => {
      const token = localStorage.getItem("authToken");
      setLoading(true);

      const payload = {
        approvalRequestTypeId: data.approvalRequestTypeId,
        ApprovalHierarchyIds: data.ApprovalHierarchyIds,
      };

      const url = `${config.baseUrl}api/LevelsAndApprovals/MapApprovalsAndApprovalTypes`;
      const method = editable ? "PUT" : "POST";

      try {
        const response = await axios({
          method,
          url,
          data: payload,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        // Check for a conflict within the response data
        if (response.data?.status === 409) {
          const errorMessage = response.data.message || "A conflict occurred.";
          toast.error(errorMessage);
        } else if (response.status >= 200 && response.status < 300) {
          setRes(response);
          toast.success(
            `Levels & Approvals ${editable ? "updated" : "added"} successfully!`
          );
          GetListApprovalsAndApprovalTypes();
          setShowDialog(false);
        } else {
          throw new Error(response.data.message || "An error occurred");
        }
      } catch (error) {
        if (error.response?.data && error.response.status === 409) {
          const errorMessage =
            error.response.data.message || "A conflict occurred.";
          toast.error(errorMessage);
        } else {
          console.error("API Error:", error.response || error.message);
          toast.error(error.response?.data?.message || "Something went wrong");
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <ToastContainer />
      <div className="main-form">
        <form onSubmit={formik.handleSubmit}>
          {loading && (
            <div className="spinner-overlay">
              <Spinner />
            </div>
          )}

          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-6">
              <label htmlFor="approvalRequestTypeId">
                Approval Request <span className="text-danger">*</span>
              </label>
              <Dropdown
                filter
                placeholder="Select Approval Request"
                value={formik.values.approvalRequestTypeId}
                options={approvalTypesData}
                onChange={(e) =>
                  formik.setFieldValue("approvalRequestTypeId", e.value)
                }
                disabled={viewable}
              />
              {formik.touched.approvalRequestTypeId &&
              formik.errors.approvalRequestTypeId ? (
                <small className="p-error">
                  {formik.errors.approvalRequestTypeId}
                </small>
              ) : null}
            </div>
            <div className="field col-12 md:col-6">
              <label>
                Approval Levels <span className="text-danger">*</span>
              </label>
              <MultiSelect
                filter
                value={formik.values.ApprovalHierarchyIds}
                options={approvalsAndLevelsData}
                optionLabel="label"
                placeholder="Select Approval Levels"
                onChange={(e) =>
                  formik.setFieldValue("ApprovalHierarchyIds", e.value)
                }
                disabled={viewable}
              />
              {formik.touched.ApprovalHierarchyIds &&
              formik.errors.ApprovalHierarchyIds ? (
                <small className="p-error">
                  {formik.errors.ApprovalHierarchyIds}
                </small>
              ) : null}
            </div>
          </div>

          {!viewable && (
            <div className="form-buttons mt-4">
              <ActionButtons
                loading={loading}
                onCancel={onHide}
                onSave={formik.handleSubmit}
                saveLabel={editable ? "Update Changes" : "Save Changes"}
                showSave={true}
                cancelLabel="Cancel"
              />
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default EditAddForm;
