import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import config from "../../../../config";
import { format, startOfMonth, endOfMonth } from "date-fns";
import EditAddForm from "./edit-add-form";
import { Dialog } from "primereact/dialog";
import Spinner from "../../components/spinner";
import editIcon from "../../../../assets/assests/edit.png";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import Axios from "axios";
import usePermissions from "../../components/hooks/usePermissions";
import { renderTextWithTooltip } from "../../components/helper";
import deleteIcon from "../../../../assets/assests/delete.png";
import { Tag } from "primereact/tag";

export default function MapApprovalsScreen() {
  const [showDialog, setShowDialog] = useState(false);
  const formatDate = (date) => format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  const getDefaultDateRange = () => {
    const fromDate = startOfMonth(new Date());
    const toDate = endOfMonth(new Date());
    return { fromDate: formatDate(fromDate), toDate: formatDate(toDate) };
  };

  const [res, setRes] = useState();
  const [dialogDetails, setDialogDeatils] = useState(false);
  const [viewable, setViewable] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [departments, setDepartments] = useState();
  const [loading, setLoading] = useState(false);
  const [levelsAndApprovals, setLevelsAndApprovals] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [employeeDateRange, setEmployeeDateRange] = useState(null);
  const [designations, setDesignations] = useState([]);
  const [rowselect, setRowselect] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);

  const permissions = usePermissions("Levels Approvals");

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const GetListApprovalsAndApprovalTypes = async () => {
    const token = localStorage.getItem("authToken");

    setLoading(true);

    try {
      const response = await fetch(
        `${config.baseUrl}api/LevelsAndApprovals/ListApprovalsAndApprovalTypes`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === 200 && Array.isArray(data.data)) {
        setLevelsAndApprovals(data.data);
      } else {
        // console.error("Unexpected data format:", data);
      }
    } catch (error) {
      console.error("Error fetching role & rights", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteAction = async (deleteId) => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await Axios.delete(
        `${config.baseUrl}api/LevelsAndApprovals/DeleteLevelAndApprovals/${deleteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      GetListApprovalsAndApprovalTypes();
      toast.success("Deleted Successfully");
      setShowDialog(false);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
    setViewable(false);
  };

  const editAction = (rowData) => {
    setIsEdit(true);
    setRowselect(rowData);
    setShowDialog(true);
  };

  const confirmDelete = (deleteId) => {
    confirmDialog({
      message: "Are you sure you want to delete?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        deleteAction(deleteId);
      },
    });
  };

  const updateAction = async (rowData) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);

      const response = await Axios.patch(
        `${config.baseUrl}api/LevelsAndApprovals/ToggleActiveDeactive/${rowData.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(response.data.message);

      // Handle success
      GetListApprovalsAndApprovalTypes();
      setShowDialog(false);
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="action-buttons flex">
        {permissions?.edit && (
          <div
            className={`toggle-switch ${
              rowData.isActive ? "active" : "inactive"
            }`}
            onClick={(event) => {
              event.stopPropagation();
              updateAction(rowData);
            }}
          >
            <div
              className={`toggle-knob ${
                rowData.isActive ? "active" : "inactive"
              }`}
            />
          </div>
        )}
        {permissions?.edit && (
          <Button
            className=" p-button-edit p-0 mr-3"
            onClick={() => {
              toggleDialogMode(true);
              editAction(rowData);
            }}
            aria-label="Edit"
          >
            <img src={editIcon} alt="Edit" />
          </Button>
        )}
        {permissions?.delete && (
          <Button
            className="p-button-rounded p-button-edit p-0 mr-3 action-images"
            onClick={() => confirmDelete(rowData.id)}
            aria-label="delete"
          >
            <img src={deleteIcon} alt="Eye" />
          </Button>
        )}
      </div>
    );
  };

  useEffect(() => {
    const { fromDate, toDate } =
      employeeDateRange && employeeDateRange[0] && employeeDateRange[1]
        ? {
            fromDate: formatDate(employeeDateRange[0]),
            toDate: formatDate(employeeDateRange[1]),
          }
        : getDefaultDateRange();

    const filterPayload = {
      designationId: selectedDesignation ? selectedDesignation.id : null,
      departmentId: selectedDepartment ? selectedDepartment.id : null,
      fromDate,
      toDate,
    };
    GetListApprovalsAndApprovalTypes(filterPayload);
  }, []);

  const columnsData = [
    { field: "approvalRequestTypeId", header: "Id" },
    {
      field: "approvalRequestTypeName",
      header: "Approval Request Name",
      body: (rowData) => (rowData.approvalRequestTypeName ? rowData.approvalRequestTypeName : "--"),
    },
    // { field: "modifiedDate", header: "Date" },
    {
      field: "approvalLevels",
      header: "Approval Levels",
      body: (rowData) => (rowData.approvalLevels ? rowData.approvalLevels : "--"),

    },
   
    ...(permissions?.view
      ? [
          // {
          //   header: "Action",
          //   body: actionTemplate,
          // },
        ]
      : []),
  ];

 

  const fetchFilters = async () => {
    try {
      const token = localStorage.getItem("authToken");

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers,
      };

      const [departmentResponse, designationResponse] = await Promise.all([
        fetch(`${config.baseUrl}api/Department/GetAll`, options),
        fetch(`${config.baseUrl}api/Posts/GetAll`, options),
      ]);

      if (!departmentResponse.ok || !designationResponse.ok) {
        throw new Error("Error fetching filter data");
      }
      const departmentsData = await departmentResponse.json();
      const filteredDep = departmentsData?.data.map((department) => ({
        value: department.id,
        label: department.name,
      }));
      setDepartments(filteredDep);
      const designationsData = await designationResponse.json();
      setDesignations(designationsData?.data);
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const onHide = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">
              {isEdit
                ? "Edit Map Approvals"
                : viewable
                ? "View Map Approvals"
                : "Add Map Approvals"}
            </div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <EditAddForm
          rowData={rowselect}
          GetListApprovalsAndApprovalTypes={GetListApprovalsAndApprovalTypes}
          setShowDialog={setShowDialog}
          editable={isEdit}
          viewable={viewable}
          onHide={onHide}
          formData={isEdit ? selectedData : null}
          setRes={setRes}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-4">
          <h5 className="pages-internal-heading">
           Map Approval Hierarchy
          </h5>
        </div>

        <div className="col-12 md:col-8 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
          {permissions?.add && (
            <Button
              label="Add Map Approvals"
              icon="pi pi-plus"
              onClick={() => {
                setIsEdit(false);
                setViewable(false);
                setShowDialog(true);
              }}
              className="p-button ml-3 mt-2"
            />
          )}
        </div>
      </div>

      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}

      <div className="card">
        <DataTable
          emptyMessage="No record found."
          value={levelsAndApprovals}
          globalFilter={globalFilter}
          className="custom-data-table"
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
}
