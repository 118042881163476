const config = {
  baseUrl: "https://qa-petncd-api.appinsnap.com/",



  authPath: "/api/Account/login",
  registerUserPath: "/api/Account/register",
  usersPath: "/api/Users",
  tellerAppPath: "/api/TellerApp",
  checkerAppPath: "/api/Admin",
  dashboardPath: "/api/AdminDashboard",
};

export default config;
